import { action, Action, ActionOn, actionOn, thunk, Thunk, computed, Computed, createStore, createTypedHooks } from 'easy-peasy';
import { StreamModel, streamStore } from './stream';
import { ConfiguratorModel, configuratorStore } from './configurator';

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export interface StoreModel {
  stream: StreamModel,
  configurator: ConfiguratorModel,
}

export const store = createStore<StoreModel>({
  stream: streamStore,
  configurator: configuratorStore,
});