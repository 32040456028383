import React, { ReactElement, Suspense } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { NavigateBefore as LeftIcon, NavigateNext as RightIcon } from '@mui/icons-material';
import { useStoreActions, useStoreState } from '../../store/store';

const Configurator = React.lazy(() => import('./Configurator'));

export type ConfiguratorDrawerProps = {
  totem: boolean
}

export default function ConfiguratorDrawer({ totem }: ConfiguratorDrawerProps): ReactElement {

  const { toggleConfigurator } = useStoreActions(state => state.configurator);
  const { isOpen } = useStoreState(state => state.configurator);
  
  return (
    <>
    <Drawer
        open={isOpen}
        onClose={toggleConfigurator}
        direction='right'
        zIndex={1000}
        enableOverlay={true}
        overlayOpacity={0}
        className='bla bla bla'
        style={{ width: '270px', height: '100vh', backgroundColor: 'white' }}
    >
      <Suspense fallback={<div></div>}>
        <Configurator totem={totem} />
      </Suspense>

      <div style={{ width: '25px', height: '48px', position: 'absolute', top: 0, left: '-25px', backgroundColor: '#F5F5F5', cursor: 'pointer', display: 'flex', alignItems: 'center', boxShadow: '-1px 1px 2px #929292' }}
        onClick={() => toggleConfigurator()}><RightIcon color='primary' /></div>
    </Drawer>
    {!isOpen && <div style={{ width: '25px', height: '48px', position: 'fixed', top: 0, right: 0, backgroundColor: '#F5F5F5', cursor: 'pointer', display: 'flex', alignItems: 'center', boxShadow: '-1px 1px 2px #929292' }}
      onClick={() => toggleConfigurator()}><LeftIcon color='primary' /></div>}
    </>
  );
}
