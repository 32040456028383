import HttpClient, { success } from "./HttpClient"
import { ILocation } from "../models/location"
import { IConfiguration } from "../models/configuration";



class LocationService {

  private client: HttpClient;

  constructor() {
    this.client = new HttpClient();
  }

  public async getLocationsByCommercial(code: string): Promise<ILocation[]> {

    const filter = encodeURIComponent(`CommercialId:'${code}'`);
    const fields = encodeURIComponent('Name,Code,LocationId,Culture,CommercialId,IsFake');
    const sort = encodeURIComponent('Name asc');

    const res = await this.client.get<any>(`/es-ES/Locations/${filter}/${sort}/100/1/${fields}`);
    if (success(res)) {
      const locations: ILocation[] = res.data.results.map((loc: any) => ({
        id: loc.locationId,
        name: loc.name,
        culture: loc.culture,
        code: loc.code,
        comercialId: loc.commercialId,
        isFake: loc.isFake,
      }));
      return locations;
    }
    return [];
  }

  public async getPresettingLocations(): Promise<ILocation[]> {

    const filter = encodeURIComponent(`'DashboardConfiguration.IsPresetting':true`);
    const fields = encodeURIComponent('Name,Code,LocationId,Culture,CommercialId,IsFake');
    const sort = encodeURIComponent('Name asc');

    const res = await this.client.get<any>(`/es-ES/Locations/${filter}/${sort}/100/1/${fields}`);
    if (success(res)) {
      const locations: ILocation[] = res.data.results.map((loc: any) => ({
        id: loc.locationId,
        name: loc.name,
        culture: loc.culture,
        code: loc.code,
        comercialId: loc.commercialId,
        isFake: loc.isFake,
      }));
      return locations;
    }
    return [];
  }

  public async saveConfiguration(locationId: string, configuration: IConfiguration): Promise<IConfiguration | undefined> {
    
    const filter = encodeURIComponent(locationId);

    console.log("LocationService.saveConfiguration", filter, configuration)

    const res = await this.client.put<IConfiguration>(`/es-ES/Locations/configuration/${filter}`, configuration);
    if (success(res)) {
      return res.data;
    }
    return undefined;
  }

  public async getConfigurationByLocationId(locationId: string): Promise<IConfiguration | undefined> {

    const filter = encodeURIComponent(`Code:'${locationId}'`);
    const fields = encodeURIComponent('DashboardConfiguration');

    const res = await this.client.get<any>(`/es-ES/Locations/${filter}/none/1/1/${fields}`);
    if (success(res)) {
      return res.data.results[0]?.dashboardConfiguration as IConfiguration || undefined;
    }
    return undefined;
  }

  public async getBusAddressByLocationId(locationId: string): Promise<string | undefined> {

    const filter = encodeURIComponent(`Code:'${locationId}'`);
    const fields = encodeURIComponent("'DashboardConfiguration.System.NatsServer'");

    const res = await this.client.get<any>(`/es-ES/Locations/${filter}/none/1/1/${fields}`);
    if (success(res)) {
      return res.data.results[0]?.dashboardConfiguration?.system?.natsServer as string || undefined;
    }
    return undefined;
  }
}

export const locationService = new LocationService();