import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { ILocation } from '../models/location';
import { metricsService } from '../services/MetricsService';
import { locationService } from '../services/LocationService';
import { IMetricsInfo } from '../models/metrics';
import { CUSTOM_PRESETTING_ID } from '../models/configuration';
import { translationService } from '../services/TranslationService';
import { ConfigText, IConfiguratorTranslations } from '../models/translation';

export interface ConfiguratorModel {

  configuratorCulture: string
  setConfiguratorCulture: Action<ConfiguratorModel, string>

  mode: string
  setMode: Action<ConfiguratorModel, "bright" | "dark">
  initializeMode: Action<ConfiguratorModel>

  logoAspectRatio: number
  setLogoAspectRatio: Action<ConfiguratorModel, number>

  configuratorTranslations: IConfiguratorTranslations | undefined
  _setConfiguratorTranslations: Action<ConfiguratorModel, IConfiguratorTranslations>
  translate: Computed<ConfiguratorModel, (code: string) => string>;
  translateList: Computed<ConfiguratorModel, (code: string) => { id: string, name: string }[]>;

  loading: boolean
  setLoading: Action<ConfiguratorModel, boolean>
  loadingLanguage: boolean
  setLoadingLanguage: Action<ConfiguratorModel, boolean>

  isOpen: boolean
  toggleConfigurator: Action<ConfiguratorModel>

  tab: number
  selectTab: Action<ConfiguratorModel, number>

  preSettings: ILocation[]
  selectedPreSetting: ILocation | undefined
  setPreSetting: Action<ConfiguratorModel, ILocation | undefined>

  code: string
  setCode: Action<ConfiguratorModel, string>
  locations: ILocation[] | undefined
  location: ILocation | undefined
  _setLocation: Action<ConfiguratorModel, ILocation>
  _setPreSettings: Action<ConfiguratorModel, ILocation[]>
  clearLocation: Action<ConfiguratorModel>
  setLocations: Action<ConfiguratorModel, ILocation[] | undefined>

  metrics: IMetricsInfo | undefined
  _setMetrics: Action<ConfiguratorModel, IMetricsInfo>
  loadConfiguratorTranslations: Thunk<ConfiguratorModel>
  loadConfiguratorData: Thunk<ConfiguratorModel, ILocation>
}

export const configuratorStore: ConfiguratorModel = {

  configuratorCulture: "es-ES",
  configuratorTranslations: undefined,

  isOpen: true,
  tab: 0,
  preSettings: [],
  selectedPreSetting: undefined,
  code: "",
  location: undefined,
  locations: undefined,
  metrics: undefined,
  mode: "bright",

  logoAspectRatio: 0,
  setLogoAspectRatio: action((state, aspectRatio) => {
    state.logoAspectRatio = aspectRatio;
  }),

  setMode: action((state, mode) => {
    state.mode = mode;
  }),

  initializeMode: action((state) => {
    state.mode = "bright";
  }),

  loading: false,
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  loadingLanguage: false,
  setLoadingLanguage: action((state, loading) => {
    state.loadingLanguage = loading;
  }),

  setConfiguratorCulture: action((state, culture) => {
    state.configuratorCulture = culture;
  }),

  toggleConfigurator: action((state) => {
    state.isOpen = !state.isOpen
  }),

  selectTab: action((state, index) => {
    state.tab = index;
  }),

  setCode: action((state, code) => {
    state.code = code;
  }),

  _setLocation: action((state, location) => {
    state.location = location;
  }),

  _setPreSettings: action((state, locations) => {
    state.preSettings = locations;
  }),

  setLocations: action((state, locations) => {
    state.locations = locations;
  }),

  setPreSetting: action((state, location) => {
    // console.log("setPreSetting", location?.name || "?");
    state.selectedPreSetting = location;
  }),

  clearLocation: action((state) => {
    state.location = undefined;
    state.metrics = undefined;
    state.preSettings = [];
    state.selectedPreSetting = undefined;
    state.metrics = undefined;
  }),

  _setMetrics: action((state, metrics) => {
    state.metrics = metrics;
  }),

  _setConfiguratorTranslations: action((state, translations) => {
    state.configuratorTranslations = translations;
  }),

  translate: computed(state => (code: string) => state.configuratorTranslations?.map.get(code) || ""),
  translateList: computed(state => (code: string) => {
    const text = state.configuratorTranslations?.map.get(code) || "";
    // console.log("translateList", code, text)
    if (text) {
      const items = text.split("|").map(item => {
        const parts = item.split("=");
        return { id: parts[1], name: parts[0] };
      });
      return items;
    }
    return [];
  }),

  loadConfiguratorTranslations: thunk(async (actions, _, { getState }) => {
    const culture = getState().configuratorCulture;

    const configuratorTranslations = await translationService.getConfiguratorTranslations(culture);
    actions._setConfiguratorTranslations(configuratorTranslations);
  }),

  loadConfiguratorData: thunk(async (actions, location, { getState }) => {

    const culture = getState().configuratorCulture;
    const personalizedTitle = getState().translate(ConfigText.Configurator_Collapsible_Sidebar_Tab_Brand_Personalized) || '';

    const [metrics, presettings] = await Promise.all([
      metricsService.getMetricsInfo(culture),
      locationService.getPresettingLocations(),
    ]);

    const custom: ILocation = {
      id: CUSTOM_PRESETTING_ID,
      code: CUSTOM_PRESETTING_ID,
      comercialId: '',
      name: personalizedTitle,
      culture: culture,
      isFake: true,
    }

    actions._setPreSettings([ custom, ...presettings ]);
    actions._setMetrics(metrics);
    actions._setLocation(location);
    actions.initializeMode();
  }),
}


