import HttpClient, { success } from "./HttpClient"
import { IMetricsInfo, MetricCategory } from "../models/metrics";
import { ITelemetry } from "../models/telemetry";

interface ICategorizedMetricsInfo {
  result: [
    {
      group: string
      category: string
      groupTitle: string
      categoryTitle: string
      metrics: [
        {
          name: string
          title: string
          description: string
        }
      ]
    }
  ]
}

class MetricsService {

  private client: HttpClient;

  constructor() {
    this.client = new HttpClient();
  }

  public async getMetricsInfo(culture: string): Promise<IMetricsInfo> {
 
    const res = await this.client.get<ICategorizedMetricsInfo>(`/${culture}/MetricsInfo/categorized`);
    if (success(res)) {

      const result = res.data?.result || [];

      const qualityTitle = result.filter(m => m.category === MetricCategory.AirQuality)[0]?.categoryTitle || '';
      const comfortTitle = result.filter(m => m.category === MetricCategory.Comfort)[0]?.categoryTitle || '';

      const pollutantsCompositesTitle = result.filter(m => m.category === MetricCategory.PollutantsComposites)[0]?.categoryTitle || '';
      const pollutantsCountTitle = result.filter(m => m.category === MetricCategory.PollutantsCount)[0]?.categoryTitle || '';
      const pollutantsWeightTitle = result.filter(m => m.category === MetricCategory.PollutantsWeight)[0]?.categoryTitle || '';
      const pollutantsTitle = result.filter(m => m.category === MetricCategory.PollutantsWeight)[0]?.groupTitle || '';
      
      const metrics: IMetricsInfo = {
        comfort: result.find(c => c.category === MetricCategory.Comfort)?.metrics
          .map(m => ({ id: m.name, name: m.title, description: m.description, category: MetricCategory.Comfort, categoryName: comfortTitle, groupName: comfortTitle })) || [],
        quality: result.find(c => c.category === MetricCategory.AirQuality)?.metrics
          .map(m => ({ id: m.name, name: m.title, description: m.description, category: MetricCategory.AirQuality, categoryName: qualityTitle, groupName: qualityTitle })) || [],
        pollutants: [],
      }
      metrics.pollutants = [ ...metrics.pollutants, ...result.filter(c => c.category === MetricCategory.PollutantsComposites)
        .flatMap(c => c.metrics.map((m) => ({ id: m.name, name: m.title, description: m.description, category: c.category, categoryName: pollutantsCompositesTitle, groupName: pollutantsTitle }))) || []];
      metrics.pollutants = [ ...metrics.pollutants, ...result.filter(c => c.category === MetricCategory.PollutantsCount)
        .flatMap(c => c.metrics.map((m) => ({ id: m.name, name: m.title, description: m.description, category: c.category, categoryName: pollutantsCountTitle, groupName: pollutantsTitle }))) || []];
      metrics.pollutants = [ ...metrics.pollutants, ...result.filter(c => c.category === MetricCategory.PollutantsWeight)
        .flatMap(c => c.metrics.map((m) => ({ id: m.name, name: m.title, description: m.description, category: c.category, categoryName: pollutantsWeightTitle, groupName: pollutantsTitle }))) || []];

      return metrics;
    }
    return { comfort: [], quality: [], pollutants: [] };
  }

  public async getFakeMetricsData(culture: string): Promise<ITelemetry | undefined> {

    const res = await this.client.get<ITelemetry>(`/${culture}/Locations/telemetry/fakedata`);
    if (success(res)) {
      return res.data as ITelemetry;
    }
    return undefined;
  }
}

export const metricsService = new MetricsService();