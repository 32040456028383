import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Stack, Typography, Box, TextField, InputAdornment, IconButton, Button, Alert, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, SxProps, Theme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material';
import { useStoreActions, useStoreState } from '../../store/store';
import { ILocation } from '../../models/location';
import Logo from '../../images/logos/logo-aire-limpio-light.svg';
import { locationService } from '../../services/LocationService';
import { ConfigText } from '../../models/translation';

function setProperty(property: string, value: string) {
  document.documentElement.style.setProperty(property, value);
} 

export type LocationProps = {
}

export default function Locations({ }: LocationProps): ReactElement<LocationProps> {

  const inputRef = useRef<HTMLInputElement>(null);

  const { translate, locations, code } = useStoreState(state => state.configurator);
  const { clearLocation, setLocations, setCode, loadConfiguratorData, loadConfiguratorTranslations, setConfiguratorCulture } = useStoreActions(state => state.configurator);

  // const [locations, setLocations] = useState<ILocation[] | undefined>(undefined);
  // const [code, setCode] = useState<string>('');
  const [incorrectCode, setIncorrectCode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [initializing, setInitializing] = useState<boolean>(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const m = searchParams.get('lang');
    let culture = 'es-ES';
    if (m) {
      culture = m.replace('lang=', '');
      if (culture !== 'es-ES' && culture !== 'en-US') {
        culture = 'es-ES';
      }
    }
    setConfiguratorCulture(culture);

    loadConfiguratorTranslations().then(() => {
      setInitializing(false);
      onRestart();
    });
  }, []);

  const onRestart = () => {
    clearLocation();
    // setLocations(undefined);
    // setCode('');
    setIncorrectCode(false);
    inputRef.current?.focus();
  }

  const onCancel = () => {
    clearLocation();
    setLocations(undefined);
    setCode('');
    setIncorrectCode(false);
    inputRef.current?.focus();
  }

  const onCodeChanged = (code: string) => {
    setCode(code);
    setLocations(undefined);
  }


  const onLocationSelected = async (location: ILocation) => {
    setLoading(true);

    setProperty('--br-color-bg', '#fafafa;');
    setProperty('--br-color-fg', 'white');
    
    localStorage.setItem('location', location.code);
    localStorage.setItem('fake', location.isFake ? 'true' : 'false');

    await loadConfiguratorData(location);
    setLoading(false);
  }

  const fetchLocations = async () => {
    setLoading(true);
    setLocations(undefined);

    const locations = await locationService.getLocationsByCommercial(code);
    setLocations(locations);
    //TODO: Set locations as undefined when receiving an error -> setLocations(undefined);

    setLoading(false);
  }
  
  return (
    <Box display="flex" justifyContent="center" alignItems="flex-start" sx={{ width: '100vw', height: '100vh', backgroundColor: '#FAFAFA', overflow: 'hidden' }}>
      <Stack direction="column" spacing={0} sx={{ width: '330px', pt: 6 }}>

        <img src={Logo} alt="SMoCAI" width="120" style={{ alignSelf: 'center' }} />

        <Typography variant="h4" alignSelf="center" sx={{ mt: 6, color: '#202020', fontWeight: '400' }}>SMoCAI® Demo</Typography>

        <TextField variant="outlined" sx={{ mt: 5 }} error={incorrectCode} disabled={loading} inputRef={inputRef} 
          label={translate(ConfigText.Configurator_Login_Screen_Commercial_Code)}
          InputProps={{
            endAdornment: (code !== '' && !loading) ? (<InputAdornment position="end"><IconButton size='small' edge="end" onClick={() => onCancel()}><CloseIcon /></IconButton></InputAdornment>) : undefined,
          }}
          value={code} onChange={(e) => onCodeChanged(e.target.value)} onKeyDown={(e) => {if (e.key === 'Enter') fetchLocations()}}
        />

        {/* WRONG COMMERCIAL CODE ALERT */}
        {/* <Alert severity="error" sx={{ mt: 1 }}>{translate(ConfigText.Configurator_Login_Screen_Validation_Wrong_Commercial_Code)}</Alert> */}

        {locations === undefined && 
        // SEARCH LOCATIONS BUTTON
        <LoadingButton loading={loading} variant="contained" color="primary" sx={{ mt: 4 }} disabled={code === '' || initializing} onClick={() => fetchLocations()}>
          {translate(ConfigText.Configurator_Login_Screen_Search_Locations)}
        </LoadingButton>}

        <LocationList sx={{ mt: 6 }} loading={loading} locations={locations} onLocationSelected={onLocationSelected} onCancel={() => onCancel()} />

      </Stack>
    </Box>
  );
}

type LocationListProps = {
  loading: boolean
  locations: ILocation[] | undefined
  onLocationSelected: (location: ILocation) => void
  onCancel?: () => void
  sx?: SxProps<Theme>
}

function LocationList({ loading, locations, onLocationSelected, onCancel = () => {}, sx }: LocationListProps): ReactElement<LocationListProps> {

  const { translate, translateList } = useStoreState(state => state.configurator);
  const [selectedLocation, setSelectedLocation] = useState<ILocation | undefined>();

  const languageItems = translateList(ConfigText.Configurator_Collapsible_Sidebar_Tab_Layout_Language_Selector); // TODO: Make own translation for this list

  useEffect(() => {
    setSelectedLocation(undefined);
  }, [locations]);

  const onSelect = (location: ILocation) => {
    if (!loading) {
      setSelectedLocation(location);
    }
  }

  return (
    <>
    <style>
      {`
        #locations-scrollbar::-webkit-scrollbar {
          width: 9px;
        }
        #locations-scrollbar::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 4px;
          opacity: 0;
        }
        #locations-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }
        #locations-scrollbar:hover::-webkit-scrollbar-thumb {
          background: #B2B2B2;
        }
        #locations-scrollbar:hover::-webkit-scrollbar-track {
          background: transparent;
        }
      `}
    </style>
    {locations !== undefined &&
      <Stack direction="column" spacing={0} sx={{ ...sx, width: '330px', height: 'calc(100vh - 410px)' }}>
        <Typography variant="subtitle2" alignSelf="center" sx={{ mb: 2, color: '#202020', fontWeight: 'bold' }}>{translate(ConfigText.Configurator_Login_Screen_Choose_Location)}</Typography>

        {locations.length > 0 ? (
          <TableContainer id='locations-scrollbar' component={Paper} sx={{ maxHeight: '350px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2" sx={{ color: '#202020', fontWeight: 'bold' }}>{translate(ConfigText.Configurator_Commercial_Data_Location)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" sx={{ color: '#202020', fontWeight: 'bold' }}>{translate(ConfigText.Configurator_Commercial_Data_Language)}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locations.map((location) => (
                  <TableRow hover={!loading} onClick={_ => onSelect(location)}
                    key={location.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: loading ? 'default' : 'pointer', backgroundColor: location.code === selectedLocation?.code ? '#EDF3FB' : undefined }}
                  >
                    <TableCell>
                      {location.name}
                    </TableCell>
                    <TableCell>{languageItems.find(li => li.id === location.culture)?.name || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ):(
          <Alert severity="error" sx={{ }}>{translate(ConfigText.Configurator_Login_Screen_Validation_No_Locations)}</Alert>
        )}

        {locations.length > 0 ? (
          // CONFIG DASHBOARD BUTTON
          <LoadingButton loading={loading} variant="contained" color="primary" sx={{ mt: 4 }} disabled={!selectedLocation} onClick={() => onLocationSelected(selectedLocation!)}>
            {translate(ConfigText.Configurator_Login_Screen_Configure_Dashboard)}
          </LoadingButton>
        ):(
          // ENTER NEW COMMERCIAL CODE BUTTON
          <Button variant="outlined" color="primary" sx={{ mt: 4 }} onClick={() => onCancel()}>
            {translate(ConfigText.Configurator_Login_Screen_Enter_New_Commercial_Code)}
          </Button>
        )}
        
      </Stack>
    }
    </>
  );
}