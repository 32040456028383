import React, { Suspense, useEffect } from 'react';
import ConfiguratorDrawer from './configurator/ConfiguratorDrawer';
import Locations from './configurator/Locations';
import { useStoreState, useStoreActions } from '../store/store';

const DefaultShell = React.lazy(() => import('./ShellDefault'));
const TotemShell = React.lazy(() => import('./ShellTotem'));

export default function Shell() {

  const { location } = useStoreState(state => state.configurator);
  const { setMode } = useStoreActions(state => state.configurator);
  const { config } = useStoreState(state => state.stream);

  // Get config mode, totem mode and QR visibility
  const searchParams = new URLSearchParams(document.location.search)
  const v = searchParams.get('v');
  const m = searchParams.get('mode');
  const l = searchParams.get('loc') || undefined;

  const configMode = m?.includes("config") || false;
  const totem = v?.includes("totem") || (configMode && config?.branding.layout.orientation === 'totem') || (!configMode && (config?.isTotem || false));
  const qr = v?.includes("qr") || (config?.branding.layout.showQR || false);
  const interactivity = v?.includes("interactive") ? "active" : "automatic"; // (config?.branding.layout.interactivity || 'automatic');

  useEffect(() => {
    darkModeTracker((isDarkMode: boolean) => {
      if (!configMode) {
        setMode(isDarkMode ? "dark" : "bright");
      }
    });
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <>
      {(configMode && location === undefined) ? (
        <Locations />
      ):(
        <>
        {!totem ? (
          <DefaultShell qr={qr} location={!configMode ? l : undefined} interactivity={interactivity} />
        ):(
          <TotemShell qr={qr} location={!configMode ? l : undefined} interactivity={interactivity} />
        )}
        {configMode && <ConfiguratorDrawer totem={totem} />}
        </>  
      )}
      </>
    </Suspense>
  );
}

const darkModeTracker = (fn: any) => {
  if (!window.matchMedia) {
    return;
  }
  const query = window.matchMedia('(prefers-color-scheme: dark)');

  fn(query.matches);
  query.addEventListener('change', (event) => fn(event.matches));
}