import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { connect, NatsConnection, Codec, JSONCodec, DebugEvents, Events, Subscription, ErrorCode, ApiError, NatsError } from "nats.ws";
import { IComfort, IPollutant, IQuality, ITelemetry, ITelemetryData, ITypable } from '../models/telemetry';
import { IConfiguration, IConfigurationData } from '../models/configuration';
import { locationService } from '../services/LocationService';
import { translationService } from '../services/TranslationService';
import { metricsService } from '../services/MetricsService';
import { IPollutantTranslations } from '../models/translation.pollutants';

const NO_ERROR = 0;
const NETWORK_ERROR = 1;
const NO_DATA_ERROR = 2;
const NO_LOCATION_ERROR = 3;

// let initialized: boolean = false;

let nc: NatsConnection | undefined = undefined;
let telemetryCodec: Codec<ITelemetry> = JSONCodec();
let telemetryRequestCodec: Codec<ITelemetryData> = JSONCodec();
let configCodec: Codec<IConfiguration> = JSONCodec();
let configRequestCodec: Codec<IConfigurationData> = JSONCodec();
let telemetrySubscription: Subscription;
let configSubscription: Subscription;

export enum ConnectionState { 
  Connected = "Connected",
  Connecting = "Connecting",
  Reconnecting = "Reconnecting",
  Disconnected = "Disconnected", 
}

interface ConnectionConfig {
  url: string
}

function isTelemetryFake(): boolean {
  const isFake = localStorage.getItem('fake') || 'false';
  return isFake === 'true'; 
}

// This function can access the backup copy of the data and use it to restore original values of telemetry metrics when in config mode.
function filterTelemetry(telemetry: ITelemetry, configuration: IConfiguration): ITelemetry {
  // Only on 
  if ((isTelemetryFake() || isConfigMode) && configuration?.data) {
    const d = configuration.data;
    // In config mode we need to keep a backup copy of the data to restore original values of telemetry metrics if needed.
    if (metricsBackup === undefined || backupCulture !== telemetry.culture) {
      backupCulture = telemetry.culture;
      metricsBackup = [ ...telemetry.comfort, ...telemetry.quality, ...telemetry.pollutants ];
    }
    // Filter configured metrics from fake telemetry data.
    telemetry.comfort = metricsBackup.filter(m => d.comfort.includes(m.type)) as IComfort[];
    telemetry.quality = metricsBackup.filter(m => d.quality.includes(m.type)) as IQuality[];
    telemetry.pollutants = metricsBackup.filter(m => d.pollutants.includes(m.type)) as IPollutant[];
  }
  return telemetry;
}

let backupCulture: string | undefined = undefined;
let metricsBackup: (IComfort | IQuality | IPollutant)[] | undefined = undefined;
const isConfigMode = new URLSearchParams(document.location.search).get('mode')?.includes("config") || false;
if (isConfigMode) {
  console.log("CONFIG MODE");
}

export interface StreamModel {

  connection: ConnectionConfig

  connectionState: ConnectionState

  loading: boolean
  initialized: boolean
  initializing: Computed<StreamModel, boolean>
  // error: Computed<StreamModel, number>
  error: number
  data: ITelemetry | undefined
  config: IConfiguration | undefined
  dirty: boolean
  text: Computed<StreamModel, (code: string) => string | undefined>;
  pollutantTranslations: IPollutantTranslations | undefined; // TODO: Remove. Use translations section in config instead
  pollutantText: Computed<StreamModel, (code: string) => string>;

  _setPollutantTranslations: Action<StreamModel, IPollutantTranslations>
  _setConnectionState: Action<StreamModel, ConnectionState>
  _setTelemetry: Action<StreamModel, ITelemetry>
  _setInitialized: Action<StreamModel, boolean>
  _setLoading: Action<StreamModel, boolean>
  setError: Action<StreamModel, number>
  setConfig: Action<StreamModel, IConfiguration>
  updateConfig: Action<StreamModel, IConfiguration>
  adjustTelemetryMetrics: Action<StreamModel>

  changeLanguage: Thunk<StreamModel, string>
  loadPollutantTranslations: Thunk<StreamModel>

  connect: Thunk<StreamModel, { locationId: string | null }>
  disconnect: Thunk<StreamModel>

  clearData: Action<StreamModel>
  clearDirty: Action<StreamModel>
}

export const streamStore: StreamModel = {

  connection: {
    url: "ws://localhost:8080",
  },

  connectionState: ConnectionState.Disconnected,

  loading: true,
  initialized: false,
  initializing: computed(state => !state.initialized && state.loading),
  error: 0,
  // error: computed(state => {
  //   // const t = state.data;

  //   // if (state.connectionState !== ConnectionState.Connected) {// && !state.initialized) {
  //   //   return 1;
  //   // }
  //   // else if (((t?.quality.length || 0 ) + (t?.comfort.length || 0) + (t?.pollutants.length  || 0)) === 0) {
  //   //   return 2;
  //   // }
  //   // return 0;
  // }),
  data: undefined,
  config: undefined,
  dirty: false,
  text: computed(state => (code: string) => state.config?.translations?.find(t => t.code === code)?.text || ""),
  pollutantTranslations: undefined,
  pollutantText: computed(state => (code: string) => state.pollutantTranslations?.map.get(code) || ""),

  _setPollutantTranslations: action((state, translations) => {
    state.pollutantTranslations = translations;
  }),

  _setConnectionState: action((state, connectionState) => {
    state.connectionState = connectionState;
  }),

  _setTelemetry: action((state, data) => {
    state.data = data;
  }),

  _setInitialized: action((state, initialized) => {
    state.initialized = initialized;
  }),

  _setLoading: action((state, loading) => {
    state.loading = loading;
  }),

  setError: action((state, error) => {
    state.error = error;
  }),

  setConfig: action((state, config) => {
    state.config = config;
    state.dirty = false;
  }),

  updateConfig: action((state, config) => {
    state.config = config;
    state.dirty = state.initialized;
  }),

  adjustTelemetryMetrics: action((state) => {
    state.data = filterTelemetry(state.data!, state.config!);
  }),

  clearData: action((state) => {
    state.initialized = false;
    state.loading = true;
    state.dirty = false;
    state.config = undefined;
    state.data = undefined;
    metricsBackup = undefined;
  }),

  clearDirty: action((state) => {
    state.dirty = false;
  }),

  connect: thunk(async (actions, { locationId }, { getState }) => {

    console.log('Location ID:', locationId || "UNKNOWN")
    if (!locationId) {
      actions.setError(NO_LOCATION_ERROR);
      return;
    }

    const state = getState();
    if (state.connectionState === ConnectionState.Connected) {
      await actions.disconnect();
    }

    // localStorage.setItem('location', locationId);
      
    actions._setConnectionState(ConnectionState.Connecting);
    // const url = localStorage.getItem("bus") || state.connection.url;

    let url = localStorage.getItem('bus') || undefined;
    if (url) {
      console.log("Bus address found in local storage:");
    }
    else {
      console.log("Bus address not found in local storage. Querying location service...");
    }
    while(!url) {
      url = await locationService.getBusAddressByLocationId(locationId);
      if (!url) {
        actions.setError(NETWORK_ERROR);
        console.log("Fetching bus address failed. Retrying in 5 seconds...");
        await new Promise(r => setTimeout(r, 5000));
      }
    }
    if (!(url.startsWith("ws://") || url.startsWith("wss://"))) {
      url = "wss://" + url;
    } 

    console.log("Bus address:", url);

    nc = await connect(
      {
        servers: [ url ],
        waitOnFirstConnect: true,
        maxReconnectAttempts: -1, // No limit
        reconnectTimeWait: 5 * 1000, // 5 seconds
        token: "s3cr3t",

        pingInterval: 120 * 1000, // 2 minutes
        maxPingOut: 2,
      },
    );

    (async () => {
      for await (const s of nc.status()) {
        switch (s.type) {
          case Events.Disconnect:
            console.log(`disconnected (${s.data})`);
            actions.setError(NETWORK_ERROR);
            break;
          case Events.Reconnect:
            console.log(`reconnected (${s.data})`);
            actions.setError(NO_ERROR);
            break;
          
          default:
            console.log(`event - ${s.type}: ${s.data}`)
        }
      }
    })().then();
  

    // Events
    (async () => {
      console.info(`connected (${nc.getServer()})`);
      actions.setError(NO_ERROR);

      actions._setConnectionState(ConnectionState.Connected);
      actions._setLoading(true);

      let config: IConfiguration;
      try {
        const configRequest = { culture: 'es-ES', locationId: locationId, mode: isConfigMode ? 'interactive' : 'display' } as IConfigurationData;
        console.log("CONFIG REQUEST:", configRequest);

        const configResponse = await nc.request(`dashboard.${locationId}.config.sync_req`, configRequestCodec.encode(configRequest), { timeout: 300000 });
        config = configCodec.decode(configResponse.data);
        
        console.log("CONFIG RECEIVED:", config);
      }
      catch(err) {
        console.log("CONFIG REQUEST FAILED:");
        logError(err);
        return;
      }

      if (validateConfig(config)) {

        actions.setConfig(config);

        try {
          const telemetryRequest = { culture: 'es-ES', locationId: locationId, mode: isConfigMode ? 'interactive' : 'display' } as ITelemetryData;
          console.log("TELEMETRY REQUEST:", telemetryRequest);

          const telemetryResponse = await nc.request(`dashboard.${locationId}.telemetry.sync_req`, telemetryRequestCodec.encode(telemetryRequest), { timeout: 300000 });
          const telemetry: ITelemetry = telemetryCodec.decode(telemetryResponse.data);
          
          console.log("TELEMETRY RECEIVED:", telemetry);

          if (validateTelemetry(telemetry)) {
            actions._setTelemetry(filterTelemetry(telemetry, config));

            if (getState().error === NO_DATA_ERROR) {
              actions.setError(NO_ERROR);
            }
          }
          else {
            actions.setError(NO_DATA_ERROR);
            console.log("TELEMETRY VALIDATION FAILED");
            return;
          }
        }
        catch(err) {
          actions.setError(NO_DATA_ERROR);
          console.log("TELEMETRY REQUEST FAILED:");
          logError(err);
          return;
        }
      }
      else {
        actions.setError(NO_DATA_ERROR); // TODO: Evaluate if this is the correct error code...
        console.log("CONFIG VALIDATION FAILED");
        return;
      }

      actions._setLoading(false);
      actions._setInitialized(true);
    })();
      
    // Telemetry Subscription
    (async () => {
      telemetrySubscription = nc.subscribe(`dashboard.${locationId}.telemetry.sync`);
      for await (const m of telemetrySubscription) {
        try {
          if (isConfigMode) {
            console.log("CONFIG MODE: Skipping telemetry subscription");
            continue;
          }
          const telemetry: ITelemetry = telemetryCodec.decode(m.data);

          console.log("TELEMETRY PUBLISH RECEIVED");

          if (validateTelemetry(telemetry)) {
            actions._setTelemetry(filterTelemetry(telemetry, getState().config!));
            // actions._setTelemetry(telemetry);

            if (getState().error === NO_DATA_ERROR) {
              actions.setError(NO_ERROR);
            }
          }
          else {
            actions.setError(NO_DATA_ERROR);
          }
        } catch (err) {
          console.log(err);
        }
      }
      console.log("Telemetry subscription closed");
    })();

    // Configuration Subscription
    (async () => {
      configSubscription = nc.subscribe(`dashboard.${locationId}.config.sync`);
      for await (const m of configSubscription) {
        try {
          const config: IConfiguration = configCodec.decode(m.data);

          console.log("CONFIG PUBLISH RECEIVED");
          console.log(`[${configSubscription.getProcessed()}]: ${JSON.stringify(config)}`);
          if (validateConfig(config)) {
            actions.setConfig(config);
          }
        } catch (err) {
          console.log(err);
        }
      }
      console.log("Configuration subscription closed");
    })();
    
  }),

  disconnect: thunk(async (actions, _, { getState }) => {
    if (getState().connectionState === ConnectionState.Connected) {
      try {
        telemetrySubscription.unsubscribe();
        configSubscription.unsubscribe();

        await nc?.flush();
      }
      catch(err: any) {
        console.log(err.toString()); 
      }
      actions._setConnectionState(ConnectionState.Disconnected);
    }
  }),

  changeLanguage: thunk(async (actions, culture, { getState }) => {
    console.log("CHANGE LANGUAGE:", culture);
    const config = getState().config;
    const locationId = localStorage.getItem('location') || undefined;
    if (locationId) {

      const [translations, pollutantTranslations] = await Promise.all([
        translationService.getDashboardTranslations(locationId, culture),
        translationService.getPollutantTranslations(culture)
      ]);
      
      if (translations.length && pollutantTranslations) {

        const telemetryRequest = telemetryRequestCodec.encode({ culture, locationId, mode: 'interactive' } as ITelemetryData);
        const telemetryResponse = await nc!.request(`dashboard.${locationId}.telemetry.sync_req`, telemetryRequest, { timeout: 300000 });
        const telemetry: ITelemetry = telemetryCodec.decode(telemetryResponse.data);

        // const telemetry = await metricsService.getFakeMetricsData(culture);
        if (telemetry) {
          if (validateTelemetry(telemetry)) {
            const newConfig: IConfiguration = { ...config!, culture, translations };
            
            actions._setTelemetry(filterTelemetry(telemetry, newConfig));
            actions.updateConfig(newConfig);
            actions._setPollutantTranslations(pollutantTranslations);

            if (getState().error === NO_DATA_ERROR) {
              actions.setError(NO_ERROR);
            }
          }
          else {
            actions.setError(NO_DATA_ERROR);
          }
          // actions._setTelemetry(telemetry);
          // actions.updateConfig({ ...config!, culture, translations });
        }
      }
    }
    
  }),

  loadPollutantTranslations: thunk(async (actions, _, { getState }) => {
    if (getState().pollutantTranslations === undefined) {
      
      const culture = getState().config?.culture;
      if (culture) {
        const translations = await translationService.getPollutantTranslations(culture);
        if (translations) {
          actions._setPollutantTranslations(translations);
        }
      }
    }
  }),
}

function removeEmptyEnergy(telemetry: ITelemetry): void {
  telemetry.comfort = telemetry.comfort.filter(c => c.type !== 'Energy' || c.type === 'Energy' && c.value !== 0);
}

function validateTelemetry(telemetry: ITelemetry): boolean {
  removeEmptyEnergy(telemetry);

  return telemetry.comfort.length > 0 || telemetry.quality.length > 0 || telemetry.pollutants.length > 0;
}

function validateConfig(config: IConfiguration): boolean {

  const branding = config?.branding;
  const standard = branding?.colorSchemes.standard;
  const darkmode = branding?.colorSchemes.darkmode;
  const background = branding?.background;
  const date = branding?.date;

  // DEFAULT VALUES

  if (date.align !== "top" && date.align !== "middle" && date.align !== "bottom") {
    config.branding.date.align = "top";
  }

  if (date.offsetUp > 4) config.branding.date.offsetUp = 4;
  if (date.offsetUp < 0) config.branding.date.offsetUp = 0;
  if (date.offsetDown > 3) config.branding.date.offsetDown = 3;
  if (date.offsetDown < 0) config.branding.date.offsetDown = 0;

  if (branding.layout.orientation !== "totem" && branding.layout.orientation !== "landscape") {
    branding.layout.orientation = "landscape";
  }

  branding.layout.interactivity = "automatic"; // TODO: Remove to enable this functionality

  return true;
  // return config.culture !== "";
}

function logError(err: any) {
  switch ((err as NatsError).code) {
    case ErrorCode.NoResponders:
      console.log("NoResponders: No one is listening to the request");
      break;
    case ErrorCode.Timeout:
      console.log("Timeout: Someone is listening but didn't respond");
      break;
    default:
      console.log("Request failed:", err);
  }
}