import React from 'react';
import ReactDOM from 'react-dom/client';
import { StoreProvider } from 'easy-peasy';
import reportWebVitals from './reportWebVitals';
// import './fonts/smocai-icons.ttf';
// import './fonts/smocai-icons.woff';
// import './fonts/smocai-icons.svg';
// import "./css/main.scss";
// import "./css/main-totem.scss";
import Shell from './components/Shell';
import { store } from './store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StoreProvider store={store}>
    <Shell />
  </StoreProvider>
);

// root.render(
//   <React.StrictMode>
//     <StoreProvider store={store}>
//       <App />
//     </StoreProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
