
export enum MetricCategory {
  Comfort = "#Comfort",
  AirQuality = "#AirQuality",
  PollutantsWeight= "#Particles_Weight",
  PollutantsComposites = "#Composites",
  PollutantsCount = "#Particles_Count",
}

export interface IMetric {
  id: string
  name: string
  description: string
  category: string
  categoryName: string
  groupName: string
}
export interface IMetricsInfo {
  comfort: IMetric[]
  quality: IMetric[]
  pollutants: IMetric[]
}