import axios, { AxiosInstance } from 'axios';

export interface IResult<T> {
  data: T | undefined
  error: string | undefined
}

export function success(result: IResult<any>): boolean {
  return result.error === undefined;
}

export default class HttpClient {

  private http: AxiosInstance;

  constructor() {
    // console.log("ORIGIN:", window.location.origin, ", HOST:", window.location.hostname);
    this.http = axios.create({
      // TODO: Use .env file to set the baseURL
      baseURL: `https://${window.location.hostname}:5002/api`, // For production
      // baseURL: 'https://5.196.185.84:5002/api', // For development
      // baseURL: '/api', // Using origin...
      timeout: 300000,
    });
  }
  
  private handleError<T>(error: any): IResult<T> {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);

      return { error: error.response.data, data: undefined };
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);

      return { error: "Network error", data: undefined };
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);

      return { error: "Unknown error", data: undefined };
    }
  }
 
  public async get<T>(url: string): Promise<IResult<T>> {
    try {
      const response = await this.http.get(url);
      return { data: response.data as T, error: undefined } as IResult<T>;
    } 
    catch (error) {
      return this.handleError<T>(error);
    }
  }

  public async post<T>(url: string, data: any): Promise<IResult<T>> {
    try {
      const response = await this.http.post(url, data);
      return { data: response.data as T, error: undefined } as IResult<T>;
    } 
    catch (error) {
      return this.handleError<T>(error);
    }
  }

  public async put<T>(url: string, data: any): Promise<IResult<T>> {
    try {
      const response = await this.http.put(url, data);
      return { data: response.data as T, error: undefined } as IResult<T>;
    } 
    catch (error) {
      return this.handleError<T>(error);
    }
  }
}