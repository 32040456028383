export const CUSTOM_PRESETTING_ID = "_CUSTOM";

export interface IColorScheme {
  foregroundColor: string
  backgroundColor: string
  logo: string
  qr: string
}

export interface ITextTranslation {
  code: string
  text: string
}

export interface IMetricsData {
  comfort: string[]
  quality: string[]
  pollutants: string[]
}

export interface IConfiguration {
  culture: string
  data: IMetricsData
  branding: {
    typography: string
    border: string // rounded, square
    statusColor: string // bright, pale
    logoMaxWidth: number
    colorSchemes: {
      standard: IColorScheme
      darkmode: IColorScheme
    }
    background: {
      primary: number[]
      secondary: number[]
      wave: number[]
      subtractiveColorWave: boolean
      showAnimation: boolean
    }
    date: {
      align: string
      offsetUp: number
      offsetDown: number
    }
    layout: {
      orientation: string,
      interactivity: string,
      showQR: boolean
    }
  }
  system: {
    natsServer: string
  }
  translations: ITextTranslation[]
  isTotem: boolean
  isPresetting: boolean
}

export const TextCodes = {
  Aqi_Description: "Dashboard_Configuration_Aqi_Description",
  CumulativeDailyExposure_Title: "Dashboard_Configuration_CumulativeDailyExposure_Title",
  CumulativeDaily_Description: "Dashboard_Configuration_CumulativeDaily_Description",
  ImpactOnHealth_Title: "Dashboard_Configuration_ImpactOnHealth_Title",
  IndoorQualityPollutants_Title: "Dashboard_Configuration_IndoorQualityPollutants_Title",
  IndoorQuality_Title: "Dashboard_Configuration_IndoorQuality_Title",
  KnowImpactOnYourHealth_Title: "Dashboard_Configuration_KnowImpactOnYourHealth_Title",
  KnowImpactOnYourHealth_QR_Title: "Dashboard_Configuration_KnowImpactOnYourHealth_QR_Title",
  OutdoorQuality_Title: "Dashboard_Configuration_OutdoorQuality_Title",

  Temperature_Title: "Temperature_Title",
  Humidity_Title: "Humidity_Title",
  Capacity_Title: "Capacity_Title",

  Modal_KnowImpactOnYourHealth_Title: "Dashboard_Configuration_Modal_KnowImpactOnYourHealth_Title",
  Modal_KnowImpactOnYourHealth_Description: "Dashboard_Configuration_Modal_KnowImpactOnYourHealth_Description",
  Dashboard_Onboarding_Continue: "Dashboard_Onboarding_Continue",
  Dashboard_Modal_Close: "Dashboard_Modal_Close",
}

export interface IConfigurationData {
  culture: string
  locationId: string
  mode: string // "display" | "interactive"
}