export interface ITranslation {
  code: string
  text: string
}

export interface IConfiguratorTranslations {
  map: Map<string, string>
}

export const ConfigText = {
  Configurator_Collapsible_Sidebar_Bar_Header_Dark_Mode: "Configurator_Collapsible_Sidebar_Bar_Header_Dark_Mode",
  Configurator_Collapsible_Sidebar_Bar_Header_Exit: "Configurator_Collapsible_Sidebar_Bar_Header_Exit",
  Configurator_Collapsible_Sidebar_Bar_Header_Light_Mode: "Configurator_Collapsible_Sidebar_Bar_Header_Light_Mode",
  Configurator_Collapsible_Sidebar_Bar_Header_Save: "Configurator_Collapsible_Sidebar_Bar_Header_Save",
  Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation: "Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation",
  Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Color_Wave: "Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Color_Wave",
  Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Gradient_Color_A: "Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Gradient_Color_A",
  Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Gradient_Color_B: "Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Gradient_Color_B",
  Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Show_Animation: "Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Show_Animation",
  Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Subtractive_Color_Wave: "Configurator_Collapsible_Sidebar_Tab_Brand_Background_Animation_Subtractive_Color_Wave",
  Configurator_Collapsible_Sidebar_Tab_Brand_Border_Style: "Configurator_Collapsible_Sidebar_Tab_Brand_Border_Style",
  Configurator_Collapsible_Sidebar_Tab_Brand_Border_Style_Selector: "Configurator_Collapsible_Sidebar_Tab_Brand_Border_Style_Selector",
  Configurator_Collapsible_Sidebar_Tab_Brand_Borders_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Borders_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Colors: "Configurator_Collapsible_Sidebar_Tab_Brand_Colors",
  Configurator_Collapsible_Sidebar_Tab_Brand_Dark_Mode: "Configurator_Collapsible_Sidebar_Tab_Brand_Dark_Mode",
  Configurator_Collapsible_Sidebar_Tab_Brand_Dark_Mode_Background: "Configurator_Collapsible_Sidebar_Tab_Brand_Dark_Mode_Background",
  Configurator_Collapsible_Sidebar_Tab_Brand_Dark_Mode_Foreground: "Configurator_Collapsible_Sidebar_Tab_Brand_Dark_Mode_Foreground",
  Configurator_Collapsible_Sidebar_Tab_Brand_Date_Offset_Down: "Configurator_Collapsible_Sidebar_Tab_Brand_Date_Offset_Down",
  Configurator_Collapsible_Sidebar_Tab_Brand_Date_Offset_Up: "Configurator_Collapsible_Sidebar_Tab_Brand_Date_Offset_Up",
  Configurator_Collapsible_Sidebar_Tab_Brand_Date_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Date_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Date_Vertical_Align_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Date_Vertical_Align_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Date_Vertical_Align_Description: "Configurator_Collapsible_Sidebar_Tab_Brand_Date_Vertical_Align_Description",
  Configurator_Collapsible_Sidebar_Tab_Brand_Date_Vertical_Align_Selector: "Configurator_Collapsible_Sidebar_Tab_Brand_Date_Vertical_Align_Selector",
  Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode: "Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode",
  Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode_Background: "Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode_Background",
  Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode_Foreground: "Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode_Foreground",
  Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode_Foreground_Selector: "Configurator_Collapsible_Sidebar_Tab_Brand_Light_Mode_Foreground_Selector",
  Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Dark_Mode: "Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Dark_Mode",
  Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Drag_File: "Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Drag_File",
  Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Light_Mode: "Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Light_Mode",
  Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Vectorial_SVG: "Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Vectorial_SVG",
  Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Width: "Configurator_Collapsible_Sidebar_Tab_Brand_Logo_Width",
  Configurator_Collapsible_Sidebar_Tab_Brand_Metric_State_Pallette_Selector: "Configurator_Collapsible_Sidebar_Tab_Brand_Metric_State_Pallette_Selector",
  Configurator_Collapsible_Sidebar_Tab_Brand_Metric_State_Pallette_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Metric_State_Pallette_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Presets: "Configurator_Collapsible_Sidebar_Tab_Brand_Presets",
  Configurator_Collapsible_Sidebar_Tab_Brand_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Typography_Family_Selector: "Configurator_Collapsible_Sidebar_Tab_Brand_Typography_Family_Selector",
  Configurator_Collapsible_Sidebar_Tab_Brand_Typography_Family_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Typography_Family_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Typography_Title: "Configurator_Collapsible_Sidebar_Tab_Brand_Typography_Title",
  Configurator_Collapsible_Sidebar_Tab_Brand_Metric_Status: "Configurator_Collapsible_Sidebar_Tab_Brand_Metric_Status",
  Configurator_Collapsible_Sidebar_Tab_Brand_Personalized: "Configurator_Collapsible_Sidebar_Tab_Brand_Personalized",
  Configurator_Collapsible_Sidebar_Tab_Layout_Interaction_Selector: "Configurator_Collapsible_Sidebar_Tab_Layout_Interaction_Selector",
  Configurator_Collapsible_Sidebar_Tab_Layout_Interaction_Title: "Configurator_Collapsible_Sidebar_Tab_Layout_Interaction_Title",
  Configurator_Collapsible_Sidebar_Tab_Layout_Language_Selector: "Configurator_Collapsible_Sidebar_Tab_Layout_Language_Selector",
  Configurator_Collapsible_Sidebar_Tab_Layout_Language_Title: "Configurator_Collapsible_Sidebar_Tab_Layout_Language_Title",
  Configurator_Collapsible_Sidebar_Tab_Layout_Orientation_Selector: "Configurator_Collapsible_Sidebar_Tab_Layout_Orientation_Selector",
  Configurator_Collapsible_Sidebar_Tab_Layout_Orientation_Title: "Configurator_Collapsible_Sidebar_Tab_Layout_Orientation_Title",
  Configurator_Collapsible_Sidebar_Tab_Layout_QR: "Configurator_Collapsible_Sidebar_Tab_Layout_QR",
  Configurator_Collapsible_Sidebar_Tab_Layout_Title: "Configurator_Collapsible_Sidebar_Tab_Layout_Title",
  Configurator_Collapsible_Sidebar_Tab_Metrics_Deselect_All: "Configurator_Collapsible_Sidebar_Tab_Metrics_Deselect_All",
  Configurator_Collapsible_Sidebar_Tab_Metrics_Selected_Pollutants: "Configurator_Collapsible_Sidebar_Tab_Metrics_Selected_Pollutants",
  Configurator_Collapsible_Sidebar_Tab_Metrics_Title: "Configurator_Collapsible_Sidebar_Tab_Metrics_Title",
  Configurator_Collapsible_Sidebar_Tab_System_Title: "Configurator_Collapsible_Sidebar_Tab_System_Title",
  Configurator_Dialogs_Deselect_All_Pollutants: "Configurator_Dialogs_Deselect_All_Pollutants",
  Configurator_Dialogs_Exit_Without_Saving: "Configurator_Dialogs_Exit_Without_Saving",
  Configurator_Login_Screen_Choose_Location: "Configurator_Login_Screen_Choose_Location",
  Configurator_Login_Screen_Commercial_Code: "Configurator_Login_Screen_Commercial_Code",
  Configurator_Login_Screen_Configure_Dashboard: "Configurator_Login_Screen_Configure_Dashboard",
  Configurator_Login_Screen_Enter_New_Commercial_Code: "Configurator_Login_Screen_Enter_New_Commercial_Code",
  Configurator_Login_Screen_Search_Locations: "Configurator_Login_Screen_Search_Locations",
  Configurator_Login_Screen_Validation_No_Locations: "Configurator_Login_Screen_Validation_No_Locations",
  Configurator_Login_Screen_Validation_Wrong_Commercial_Code: "Configurator_Login_Screen_Validation_Wrong_Commercial_Code",
  Configurator_Snackbars_Configuration_Saved: "Configurator_Snackbars_Configuration_Saved",
  Configurator_Snackbars_Error_Saving: "Configurator_Snackbars_Error_Saving",
  Configurator_Snackbars_Only_Can_Select_20_Pollutants: "Configurator_Snackbars_Only_Can_Select_20_Pollutants",
  Configurator_Snackbars_Saving_Configuration: "Configurator_Snackbars_Saving_Configuration",
  Configurator_Snackbars_X_Of_X_Pollutants: "Configurator_Snackbars_X_Of_X_Pollutants",
  Configurator_Commercial_Data_Location: "Configurator_Commercial_Data_Location",
  Configurator_Commercial_Data_Language: "Configurator_Commercial_Data_Language",
  Configurator_Commercial_Data_Language_Selector: "Configurator_Commercial_Data_Language_Selector",
}

// export interface IDashboardTranslations {
//   map: Map<string, string>
// }

// export const DashboardText = {
//   Capacity_Title: "Capacity_Title",
//   Dashboard_Configuration_Aqi_Description: "Dashboard_Configuration_Aqi_Description",
//   Dashboard_Configuration_CumulativeDailyExposure_Title: "Dashboard_Configuration_CumulativeDailyExposure_Title",
//   Dashboard_Configuration_CumulativeDaily_Description: "Dashboard_Configuration_CumulativeDaily_Description",
//   Dashboard_Configuration_ImpactOnHealth_Title: "Dashboard_Configuration_ImpactOnHealth_Title",
//   Dashboard_Configuration_IndoorQualityPollutants_Title: "Dashboard_Configuration_IndoorQualityPollutants_Title",
//   Dashboard_Configuration_IndoorQuality_Title: "Dashboard_Configuration_IndoorQuality_Title",
//   Dashboard_Configuration_KnowImpactOnYourHealth_Title: "Dashboard_Configuration_KnowImpactOnYourHealth_Title",
//   Dashboard_Configuration_KnowImpactOnYourHealth_QR_Title: "Dashboard_Configuration_KnowImpactOnYourHealth_QR_Title",
//   Dashboard_Configuration_OutdoorQuality_Title: "Dashboard_Configuration_OutdoorQuality_Title",
//   Humidity_Title: "Humidity_Title",
//   IndoorQualityIndex_Title: "IndoorQualityIndex_Title",
//   OutdoorQualityIndex_Title: "OutdoorQualityIndex_Title",
//   Renovations_Title: "Renovations_Title",
//   Temperature_Title: "Temperature_Title",
// }