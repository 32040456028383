import HttpClient, { success } from "./HttpClient"
import { ConfigText, IConfiguratorTranslations, ITranslation } from "../models/translation";
import { IPollutantTranslations } from "../models/translation.pollutants";

interface ITranslationsResponse {
  results: ITranslation[]
}

class TranslationService {

  private client: HttpClient;

  constructor() {
    this.client = new HttpClient();
  }

  public async getTranslations(culture: string, modules: string[]): Promise<ITranslation[]> {
 
    const joinedModules = modules.map(m => `'${m}'`).join(",");

    const filter = encodeURIComponent(`Modules:{$in:[${joinedModules}]},Culture:'${culture}'`);
    const fields = encodeURIComponent('Code,Text');

    const filter2 = `Modules:{$in:[${joinedModules}]},Culture:'${culture}'`;
    const fields2 = 'Code,Text';

    // console.log(`/es-ES/Translations/${filter2}/none/100000/1/${fields2}`)

    const res = await this.client.get<ITranslationsResponse>(`/es-ES/Translations/${filter}/none/100000/1/${fields}`);
    if (success(res)) {
      return res.data?.results || [];
    }
    return [];
  }

  public async getConfiguratorTranslations(culture: string): Promise<IConfiguratorTranslations> {
 
    const translations = await this.getTranslations(culture, ["configurator"]);
    let t: IConfiguratorTranslations = { map: new Map<string, string>() };
    
    translations.forEach((translation: ITranslation) => {
      t.map.set(translation.code, translation.text);
    });
    return t;
  }

  public async getPollutantTranslations(culture: string): Promise<IPollutantTranslations> {
 
    const translations = await this.getTranslations(culture, ["pollutants"]);
    let t: IPollutantTranslations = { map: new Map<string, string>() };
    
    translations.forEach((translation: ITranslation) => {
      t.map.set(translation.code, translation.text);
    });
    return t;
  }

  public async getDashboardTranslations(locationId: string, culture: string): Promise<ITranslation[]> {
 
    const res = await this.client.get<any>(`/${culture}/Locations/configuration/${encodeURIComponent(locationId)}/false`);
    if (success(res)) {
      return (res.data.translations || []).map((t: any) => ({ code: t.code, text: t.text }));
    }
    return [];
  }
}

export const translationService = new TranslationService();

